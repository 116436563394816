.workflow-history-row {
  &:hover {
    background: #f9f6f6;
    cursor: pointer;
    .history-row-more {
      color: #598efb;
    }
  }
  &.has-parent {
    padding-left: 3rem !important;
  }
  .row-logo {
    color: #598efb;
    font-size: 1.5rem;
  }
}

.workflow-history-row-more {
  width: 26px;
  height: 26px;
}
