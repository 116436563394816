.monitoring-tabs {
  margin-top: 0.75rem;
  box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  .nav-link {
    color: #223d59;
    background-color: #ffffff;
    border-color: #dee2e6 !important;
    border-radius: 0;
    &:first-child {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
    &.active {
      color: #598efb !important;
    }
  }
}
