.editable-text {
  svg {
    width: 15px;
    height: 15px;
    color: gray;
    cursor: pointer;
    margin-left: 5px;
    margin-top: -2px;
  }
}
