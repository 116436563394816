.nav-menu {
  .nav-item {
    display: flex;
    align-items: baseline;
    svg {
      margin-right: 0.5rem;
      color: #598efb;
    }
  }
}
