html,
body,
#root {
  height: 100%;
}
body {
  background-color: #f6f9ff;
  font-family: Source Sans Pro, -apple-system, system-ui, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #263550;
  font-size: 1.1rem;
  line-height: 1.375;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: #223d59;
}
a {
  color: #598efb;
}

code {
  color: #344050;
}

.subtitle {
  font-weight: 400;
  color: #5e6e82;
  font-size: 1.1rem;
  margin-bottom: 0;
}

.ecosystem-logo {
  margin-top: -16px;
}
.ecosystem-navbar {
  padding-left: 40px;
  background-color: #fff;
  border-bottom: 3px solid #598efb;
}

.nav-icon {
  color: #598efb;
  font-size: 0.9rem;
  margin-right: 0.5rem;
  margin-bottom: 0.3rem;
}
.container {
  padding-top: 20px;
}

.container-fluid .breadcrumb {
  padding-top: 1rem;
}
.card-header {
  padding: 1rem 1.25rem;
  background-color: #fff;
  border-bottom: 0;
}
.card-header h5 {
  margin-bottom: 0;
  font-size: 1.5rem;
}
.card-header:first-child {
  border-radius: calc(0.375rem - 0px) calc(0.375rem - 0px) 0 0;
}
.card {
  box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  border-radius: 0.375rem;
}

.card-footer {
  font-weight: 200;
  background-color: #fff;
}
.bg-light {
  background-color: #f9fafd !important;
}

.form-control {
  border: 1px solid #d8e2ef;
}
.form-control::placeholder {
  color: #b8bec5;
  opacity: 1;
}

.form-control.select-default {
  color: #b8bec5;
  opacity: 1;
}

.form-group {
  margin-bottom: 1.5rem;
}
.form-label {
  margin-bottom: 0.6rem;
}
.form-check-input {
  margin-top: 0.6rem;
}
.form-check-label {
  margin-top: 0.4rem;
}

.toast-body {
  color: #344050;
  min-width: 300px;
}
.toast-success .toast-header {
  border-top: 3px solid #00864e;
  color: #00864e;
}

.toast-warning .toast-header {
  border-top: 3px solid #f5803e;
  color: #f5803e;
}

.toast-error .toast-header {
  border-top: 3px solid #e63757;
  color: #e63757;
}

.toast-info .toast-header {
  border-top: 3px solid #598efb;
  color: #598efb;
}

.btn {
  padding: 0.275rem 0.8rem;
  font-weight: 400;
}
.btn-block {
  text-transform: uppercase;
}
.btn-sm {
  font-size: 1rem;
}
.btn svg {
  height: 0.8rem;
  position: relative;
  top: -2px;
}
.btn-lg {
  line-height: 1.3 !important;
}

.btn-pagination svg {
  height: 1rem;
}
.btn-primary {
  background-color: #598efb;
}

.btn-outline-primary {
  border-color: #598efb;
}
.btn-light {
  color: #4d5969;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1),
    0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
    0 1px 2px 0 rgba(0, 0, 0, 0.08);
}
.btn-light:hover {
  color: #010305 !important;
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.1),
    0 3px 9px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08),
    0 1px 2px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  border-color: #fff;
}

.btn-inline-primary {
  color: #266cf7 !important;
  background-color: #f2f6ff;
  font-weight: 600;
  border: 0px;
  border-radius: 32px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out,
    transform 0.3s ease-out, opacity 0.3s ease-out,
    -webkit-transform 0.3s ease-out;
}
.btn-inline-alpha {
  color: white !important;
  background-color: #598efb !important;
  font-weight: 600;
  border: 0px;
  border-radius: 32px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out,
    transform 0.3s ease-out, opacity 0.3s ease-out,
    -webkit-transform 0.3s ease-out;
}

.btn-inline-danger {
  color: #932338 !important;
  background-color: #f8e1e4;
  font-weight: 600;
  border: 0px;
  border-radius: 32px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out,
    transform 0.3s ease-out, opacity 0.3s ease-out,
    -webkit-transform 0.3s ease-out;
}

.btn-inline-success {
  color: #00864e !important;
  background-color: #ccf6e4;
  font-weight: 600;
  border: 0px;
  border-radius: 32px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out,
    transform 0.3s ease-out, opacity 0.3s ease-out,
    -webkit-transform 0.3s ease-out;
}

.btn-inline-warning {
  color: #c46632 !important;
  background-color: #fde6d8;
  font-weight: 600;
  border: 0px;
  border-radius: 32px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out,
    transform 0.3s ease-out, opacity 0.3s ease-out,
    -webkit-transform 0.3s ease-out;
}

.btn-inline-primary:hover,
.btn-inline-alpha:hover,
.btn-inline-danger:hover,
.btn-inline-success:hover,
.btn-inline-warning:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transform: translateY(-1px);
}

.btn-variables {
  margin-left: 1rem;
  padding: 0.1rem 0.2rem;
  line-height: 0.7rem;
  position: relative;
  top: -2px;
}
.btn-variables svg {
  height: 1rem;
}
.badge.badge-pill {
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
}
.badge {
  padding: 0.3em 0.6em;
}
.badge svg {
  position: relative;
  top: -1px;
}
.badge-primary {
  background-color: #598efb;
}
.badge-secondary {
  background-color: #c8ced6;
}

.badge-soft-success {
  color: #00864e;
  background-color: #ccf6e4;
}

.badge-soft-danger {
  color: #932338;
  background-color: #fad7dd;
}

.badge-soft-warning {
  color: #c46632;
  background-color: #fde6d8;
}

.anonymize-input .input-group-text,
.anonymize-input .form-control {
  background-color: #fff;
}
.anonymize-input .input-group-prepend .input-group-text {
  border-right: 0;
  padding-right: 0.1rem;
}
.anonymize-input .input-group-append .input-group-text:first-child {
  padding-left: 0.1rem;
  border-left: 0;
}
.anonymize-input .form-control {
  border-left: 0;
  border-right: 0;
}
.tooltip {
  z-index: 1030 !important;
}
.table-sm {
  border-collapse: collapse;

  font-weight: 300;
  margin-bottom: 0.3rem;
}
.table-sm thead {
  font-weight: 400;
  background-color: #f4f4f4 !important;
}
.table-sm thead th {
  vertical-align: bottom;
  border-bottom: 0px;
  border-top: 0px;
  padding: 1rem;
}

.table-sm td,
.table-sm th {
  vertical-align: middle;
  padding: 0.8rem;
}
.table-sm td:first-child,
.table-sm th:first-child {
  padding-left: 1.4rem;
}

.table-sm td:last-child,
.table-sm th:last-child {
  padding-right: 1.4rem;
}

.data-table thead th {
  color: #598efb;
  background-color: #fff;
}

.data-table th,
.data-table td {
  color: #344050;
  padding: 0.2rem 0.5rem;
}
.data-table tbody tr {
  background-color: #fff;
}

.breadcrumb {
  padding: 0.25rem 0.2rem;
  margin-bottom: 0.75rem;
  background-color: transparent;
}

.breadcrumb a.active {
  color: #598efb;
}

.modal-xxl .modal-dialog {
  max-width: 1100px;
}
.modal-fullscreen .modal-dialog {
  max-width: 95vw;
}
.modal-playground .modal-body {
  max-height: 75vh;
  overflow-y: scroll;
}

.sticky-top {
  top: 0.5rem;
}

.tabs-call {
  margin: 0;
}

.nav-pills .nav-link {
  padding: 0.2rem 0.5rem;
  margin-right: 0.5rem;
}
.nav-pills .nav-link.active {
  background-color: #598efb;
}
.tab-pane {
  padding: 0.75rem 0;
}

.ecosystem-navbar .navbar-text {
  margin-right: 14px;
  font-size: 1.3rem;
}

.ecosystem-logo img {
  height: 45px;
  margin-left: -30px;
  margin-bottom: -16px;
}

.ecosystem-logo a {
  margin-left: 11px;
}

.informations h6 {
  font-weight: 600;
  margin-bottom: 1rem;
}

.informations .information-title {
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.informations .information {
  font-weight: 200;
}

/** Channel types **/
.channel-types .col-3 {
  margin-bottom: 2rem;
  min-height: 210px;
}
.channel-type {
  position: relative;
  transition: box-shadow 0.3s;
  padding: 2rem 1.5rem 1.5rem 1.5rem;
  cursor: pointer;
  height: 100%;
}
.channel-type-disabled {
  cursor: not-allowed;
}
.channel-type-disabled h5,
.channel-type-disabled p,
.channel-type-disabled svg {
  color: #b8bec5 !important;
}

.channel-type:hover {
  box-shadow: 0 14px 25px 0 rgba(59, 65, 94, 0.1),
    0 14px 25px 0 rgba(0, 0, 0, 0.07);
}
.channel-type-selected {
  border: 1px solid #598efb;
}

.channel-type-title {
  display: flex;

  margin-bottom: 0.5rem;
}
.channel-type-title h5 {
  font-weight: 400;
}
.channel-type-title svg {
  margin-right: 0.5rem;

  font-size: 1.4rem;
  color: #598efb;
}

.channel-type p {
  font-size: 0.9rem;
  line-height: 1.5rem;
}

.channel-type-version {
  width: auto;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.1rem;
}
.react-datepicker {
  width: max-content;
}
.react-datepicker-wrapper {
  display: block;
}
