.editor-breadcrumb .breadcrumb {
  margin-bottom: 0;
}

.workflow-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.workflow-editor-container {
  display: flex;
  height: calc(100% - 75px); // size of card header
}

.workflow-sidebar-container {
  position: relative;
  max-width: 50%;
  max-height: calc(100vh - 75px);
  display: flex;
  background-color: rgb(255, 255, 255);
  border-left: 1px solid rgb(226, 235, 248);
  position: relative;
  z-index: 1;

  .workflow-sidebar-resize {
    position: absolute;
    cursor: ew-resize;
    width: 16px;
    top: 0px;
    bottom: 0px;
    left: -8px;
  }

  .workflow-sidebar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden scroll;
    padding-bottom: 50px;
  }
}

.workflow-editor {
  position: relative;
  display: flex;
  height: 100%;
  max-height: calc(100vh - 75px);
  padding-top: 50px;
  flex: 1 1 0%;
  justify-content: center;
  overflow: scroll;

  .node-container {
    position: relative;
    min-height: 30px;
    // max-width: 325px;
    display: flex;
    justify-self: center;
    flex-direction: column;
    align-items: center;
    height:100%;
  }

  .node {
    width: 250px;

    display: flex;
    flex-direction: column;
    position: relative;

    .node-name {
      font-size: 1rem;
      font-weight: 600;
    }
    .node-body {
      padding: 1rem;
      cursor: pointer;
    }
    .node-icon {
      padding: 0;
      padding-left: 0.5rem;
      svg {
        color: #598efb;
        margin-top: -4px;
        width: 20px;
        height: 20px;
      }
    }
    .node-icon-http svg {
      margin-top: -3px !important;
      width: 30px !important;
      height: 30px !important;
    }
  }

  .node-filler {
    width: 100%;
    flex-grow: 1;
    position: relative;
    .new-node {
      display: none;
      position: absolute;
      top: calc(50% - 30px);
      left: calc(50% - 20px);
      padding-bottom: 5px;
    }
    &:hover .new-node {
      display: block;
    }
  }

  .node-arrow {
    width: 100%;
    height: 5rem;
    position: relative;
    .new-node {
      display: none;
      position: absolute;
      top: calc(50% - 30px);
      left: calc(50% - 20px);
      padding-bottom: 5px;
    }
    &:hover .new-node {
      display: block;
    }
  }

  .node-arrow-svg {
    width: 100%;
    height: 100%;
  }

  .node-arrow-svg-cond {
    width: 100%;
  }

  .node-next {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .node-foreach {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    margin-bottom: -30px;
    .node-foreach-for {
      width: 50%;
      height: calc(100% - 20px);
      border-top: 3px solid rgb(94, 99, 107);
      border-left: 3px solid rgb(94, 99, 107);
      border-bottom: 3px solid rgb(94, 99, 107);
      border-radius: 5px;
      position: absolute;
      top: 30px;
      left: 2px;
    }
  }

  .node-conditional {
    display: flex;

    justify-content: space-between;
    position: relative;

    .new-node {
      justify-content: center;
      min-width: 325px;
      align-self: start;
      padding-bottom: 5px;
    }
  }

  .node-conditional-arrow {
    display: flex;
    justify-content: center;
  }

  .node-condition-arrow {
    position: relative;
    width:100%;
    .node-condition-label {
      position: absolute;
      width: 60px;
      height: 32px;
      box-sizing: border-box;
      top: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: rgba(226, 235, 248, 0.5) 0px 15px 10px;
      background: rgb(255, 255, 255);
      border-width: 1px;
      border-color: rgb(226, 235, 248);
      border-radius: 5px;
      &-true {
         left: 30%;
        span {
          color: green;
        }
      }
      &-false {
        right: 30%;
        span {
          color: red;
        }
      }
    }
  }

  .editor-new-node {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
  }

  @keyframes grow {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  .new-node {
    display: flex;
    align-self: center;
    padding-top: 5px;
    padding-bottom: 40px;
  }
  .new-node-button {
    font-size: 1rem;
    padding: 0;
    animation: grow 0.5s;
    width: 40px;
    height: 40px;
  }
}

.node-form {
  .node-form-header {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    .header-parts {
      display: flex;
      flex: 0 0 100%;
      align-items: flex-start;
      justify-content: space-between;
      .available-variables {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
  .node-form-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  }
  .form-title {
    display: flex;
  }
}

.tooltip {
  z-index: 1060 !important;
}
