.new-node-form-body {
  padding: 0 !important;

  .new-node-list {
    .list-group-item:first-child {
      border-top: 0;
    }
    .new-node-type {
      display: flex;
      border-left: 0px;
      border-right: 0px;
      padding: 1.1rem;
      &.new-node-type-disabled {
        .new-node-logo svg {
          color: rgb(115, 124, 134);
        }
      }

      &:focus {
        outline: none;
      }

      .new-node-logo {
        padding: 0;
        padding-left: 0.5rem;
        svg {
          color: #598efb;
          margin-top: 5px;
          width: 30px;
          height: 30px;
        }
      }
      .new-node-type-container {
        margin-left: 2rem;
        .new-node-description {
          font-weight: 300;
          color: rgb(115, 124, 134);
        }
      }
    }
  }
}
