.stats {
  path.link {
    fill: none;
    stroke-width: 1;
    stroke-opacity: 0.5;
  }

  circle.count {
    stroke-width: 0.1;
    fill-opacity: 0.5;
  }

  text.subscriber,
  text.user {
    font-size: 0.2rem;
  }
}
