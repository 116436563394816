.node-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.node {
  transition: box-shadow 0.3s;
}
.node-closed {
  border: 1px solid #598efb;
  box-shadow: 0 14px 15px -10px rgba(59, 65, 94, 0.4);
}

.node .node-header {
  cursor: pointer;
}
.node .node-icon {
  padding: 0;
  padding-left: 0.5rem;
}

.node .node-icon svg {
  color: #598efb;
  margin-top: -4px;
  width: 20px;
  height: 20px;
}
.node .node-icon-http svg {
  margin-top: -3px !important;
  width: 30px !important;
  height: 30px !important;
}

.node .node-actions {
  padding: 0;
}
.node .node-delete {
  padding: 0.2rem 0.4rem !important;
  border: 0;
}

.node .node-settings {
  padding: 0.2rem 0.4rem;
  border: 0;
}

.node-arrow {
  width: 100%;
  height: 7rem;
  position: relative;
}
.node-arrow-svg {
  width: 100%;
  height: 100%;
}

.node-arrow:hover .node-add-request {
  display: block;
}

.node-add-request {
  display: none;
  top: 30%;
  left: 50%;
  margin-left: -16px;
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  animation: grow 0.5s;
  padding: 0;
}

@keyframes grow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.node-closed > .card-header {
  border-radius: calc(0.375rem);
  border-bottom: 0 !important;
}
.node > .card-header {
  padding-bottom: 0.6rem;
  padding-top: 0.6rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.node > .card-header h6 {
  margin-bottom: 0;
}

.editor-new-node {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
}

.editor-save {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.editor-save svg {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}
