.history-row:hover {
  background: #f9f6f6;
  cursor: pointer;
}

.history-row:hover .history-row-more {
  color: #598efb;
}

.history-row-more {
  width: 26px;
  height: 26px;
}
